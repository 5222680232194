<template>

    <ReportTotals :active-project-default='activeProjectDefault' :is-project-report='false' :report='report' />

    <div class='grid'>
        <div class='col-12 mt-4'>
            <div>
                <Toast />
                <Toolbar class='mb-2'>
                    <template v-slot:start>
                        <div>
                                Başlangıç: <Calendar id='startDate' :showIcon='true' dateFormat='dd.mm.yy - DD' :showButtonBar='true' v-model='startDate' class="mr-3"></Calendar> 
                                Bitiş: <Calendar id='endDate' :showIcon='true' dateFormat='dd.mm.yy - DD' :showButtonBar='true' v-model='endDate' class="mr-3"></Calendar>
                                <Button label='Filtrele' icon='pi pi-filter' class='p-button-primary' @click='refreshFoodPaymentLists' />
                        </div>
                    </template>

                    <template v-slot:end>
                        <Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
                            <h3>Hakediş Listesi</h3>
                            <p class="text-lg">
                                <b>NE İŞE YARAR?</b><br>
                                Bu sayfada belirleyeceğiniz tarih aralıklarında günlük hakediş toplamlarını raporlayabilirsiniz.<br>
                                <br>
                                Hakediş işlemi yapmadığınız birgün var ise kolayca tespit etmenize yarar.  <br>
                                <br>
                                <b>EXCEL'E AKTAR</b><br>
                                Bu fonksiyon ile filtrelemiş olduğunuz verileri Excel dosyası formatında bilgisayarınıza indirebilirsiniz.
                                <br>
                            </p>
                        </Sidebar>

                        <Button label='Excele Aktar' icon='pi pi-file-excel' class='p-button-success mr-2' @click='exportExcel'></Button>
                        <Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true' class='p-button-danger' />         
                    </template>
                </Toolbar>

                <DataTable ref='dt' :value='foodPaymentList' dataKey='id' :paginator='true' :rows='100' :filters='filters' :loading='loadingIndicator' paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown' :rowsPerPageOptions='[5,10,25,50,100,200,300]' currentPageReportTemplate='{first} Sayfada {last} Kayıt Gösteriliyor. Toplam Kayıt: {totalRecords}' responsiveLayout='scroll' showGridlines stripedRows :globalFilterFields="['name','data.paymentDate']">
                    <template #header>
                        <div class='flex flex-column md:flex-row md:justify-content-between md:align-items-center'>
                            <h5 class='mb-3 mt-3'>Hakediş Listesi</h5>
                            <span class='block mt-2 md:mt-0 p-input-icon-left'>
                                <i class='pi pi-search' />
                                <InputText v-model="filters['global'].value" placeholder='Arama...' />
                            </span>
                        </div>
                    </template>

                    <template #loading>
                        Hakedişler Hesaplanıyor. Lütfen Bekleyin.
                    </template>

                    <template #empty>
                        Seçili Projeye Ait Hakediş Bulunamadı.
                    </template>

                    <Column field='paymentDate' header='Tarih' :sortable='false'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Tarih</span>
                            {{ formatDate(slotProps.data.paymentDate) }}
                        </template>
                    </Column>

                    <Column field='project.name' header='Proje' :sortable='false' class="hidden">
                    </Column>

                    <Column field='breakfastExpectedValue' header='Sabah Sipariş' :sortable='false' style='width:5.5rem;' bodyClass='text-center' v-if='activeProjectDefault.isBreakfastVisible && activeProjectDefault.isBreakfastOrderVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Sabah Sipariş</span>
                            <span class="validQtyDefault">{{ slotProps.data.breakfastExpectedValue }}</span>
                        </template>
                    </Column>

                    <Column field='breakfastActualValue' header='Sabah Servis' :sortable='false' style='width:7rem;' bodyClass='text-center' v-if='activeProjectDefault.isBreakfastVisible && activeProjectDefault.isBreakfastOrderVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Sabah Servis</span>
                            <span :class='actualQtyClass(slotProps.data.breakfastActualValue)'>{{ slotProps.data.breakfastActualValue }}</span>
                        
                            <i class='ml-2' v-if='activeProjectDefault.isBreakfastOrderVisible && slotProps.data.breakfastActualValue < slotProps.data.breakfastExpectedValue '><img src='/layout/images/icon/down.svg' height='9'/></i>
                            <i class='ml-2' v-if='activeProjectDefault.isBreakfastOrderVisible && slotProps.data.breakfastActualValue > slotProps.data.breakfastExpectedValue'><img src='/layout/images/icon/up.svg' height='9'/></i>
                            <i class='ml-2' v-if='activeProjectDefault.isBreakfastOrderVisible && slotProps.data.breakfastActualValue == slotProps.data.breakfastExpectedValue '><img src='/layout/images/icon/minus.svg' height='9'/></i>
                        </template>
                    </Column>

                    <Column field='breakfastCalculatedValue' header='Sabah Satış' :sortable='false' style='width:5.5rem;' bodyClass='text-center' v-if='activeProjectDefault.isBreakfastVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Sabah Satış</span>
                            <span class="validQtyDefault">{{ slotProps.data.breakfastCalculatedValue }}</span>
                        </template>
                    </Column>

                    <Column field='lunchExpectedValue' header='Öğlen Sipariş' :sortable='false' style='width:5.5rem;' bodyClass='text-center' v-if='activeProjectDefault.isLunchVisible && activeProjectDefault.isLunchOrderVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Öğlen Sipariş</span>
                            <span class="validQtyDefault">{{ slotProps.data.lunchExpectedValue }}</span>
                        </template>
                    </Column>

                    <Column field='lunchActualValue' header='Öğlen Servis' :sortable='false' style='width:7rem;' bodyClass='text-center' v-if='activeProjectDefault.isLunchVisible && activeProjectDefault.isLunchOrderVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Öğlen Servis</span>
                            <span :class='actualQtyClass(slotProps.data.lunchActualValue)'>{{ slotProps.data.lunchActualValue }}</span>
                        
                            <i class='ml-2' v-if='activeProjectDefault.isLunchOrderVisible && slotProps.data.lunchActualValue < slotProps.data.lunchExpectedValue'><img src='/layout/images/icon/down.svg' height='9'/></i>
                            <i class='ml-2' v-if='activeProjectDefault.isLunchOrderVisible && slotProps.data.lunchActualValue > slotProps.data.lunchExpectedValue'><img src='/layout/images/icon/up.svg' height='9'/></i>
                            <i class='ml-2' v-if='activeProjectDefault.isLunchOrderVisible && slotProps.data.lunchActualValue == slotProps.data.lunchExpectedValue'><img src='/layout/images/icon/minus.svg' height='9'/></i>
                        </template>
                    </Column>

                    <Column field='lunchCalculatedValue' header='Öğlen Satış' :sortable='false' style='width:5.5rem;' bodyClass='text-center' v-if='activeProjectDefault.isLunchVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Öğlen Satış</span>
                            <span class="validQtyDefault">{{ slotProps.data.lunchCalculatedValue }}</span>
                        </template>
                    </Column>

                    <Column field='dinnerExpectedValue' header='Akşam Sipariş' :sortable='false' style='width:5.5rem;' bodyClass='text-center'  v-if='activeProjectDefault.isDinnerVisible && activeProjectDefault.isDinnerOrderVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Akşam Sipariş</span>
                            <span class="validQtyDefault">{{ slotProps.data.dinnerExpectedValue }}</span>
                        </template>
                    </Column>

                    <Column field='dinnerActualValue' header='Akşam Servis' :sortable='false' style='width:7rem;' bodyClass='text-center' v-if='activeProjectDefault.isDinnerVisible && activeProjectDefault.isDinnerOrderVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Akşam Servis</span>
                            <span :class='actualQtyClass(slotProps.data.dinnerActualValue)'>{{ slotProps.data.dinnerActualValue }}</span>
                        
                            <i class='ml-2' v-if='activeProjectDefault.isDinnerOrderVisible && slotProps.data.dinnerActualValue < slotProps.data.dinnerExpectedValue'><img src='/layout/images/icon/down.svg' height='9'/></i>
                            <i class='ml-2' v-if='activeProjectDefault.isDinnerOrderVisible && slotProps.data.dinnerActualValue > slotProps.data.dinnerExpectedValue'><img src='/layout/images/icon/up.svg' height='9'/></i>
                            <i class='ml-2' v-if='activeProjectDefault.isDinnerOrderVisible && slotProps.data.dinnerActualValue == slotProps.data.dinnerExpectedValue'><img src='/layout/images/icon/minus.svg' height='9'/></i>
                        </template>
                    </Column>

                    <Column field='dinnerCalculatedValue' header='Akşam Satış' :sortable='false' style='width:5.5rem;' bodyClass='text-center' v-if='activeProjectDefault.isDinnerVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Akşam Satış</span>
                            <span class="validQtyDefault">{{ slotProps.data.dinnerCalculatedValue }}</span>
                        </template>
                    </Column>

                    <Column field='nightMealExpectedValue' header='Gece Sipariş' :sortable='false' style='width:5.5rem;' bodyClass='text-center' v-if='activeProjectDefault.isNightMealVisible && activeProjectDefault.isNightMealOrderVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Gece Sipariş</span>
                            <span class="validQtyDefault">{{ slotProps.data.nightMealExpectedValue }}</span>
                        </template>
                    </Column>

                    <Column field='nightMealActualValue' header='Gece Servis' :sortable='false' style='width:7rem;' bodyClass='text-center' v-if='activeProjectDefault.isNightMealVisible && activeProjectDefault.isNightMealOrderVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Gece Servis</span>
                            <span :class='actualQtyClass(slotProps.data.nightMealActualValue)'>{{ slotProps.data.nightMealActualValue }}</span>
                        
                            <i class='ml-2' v-if='activeProjectDefault.isNightMealOrderVisible && slotProps.data.nightMealActualValue < slotProps.data.nightMealExpectedValue'><img src='/layout/images/icon/down.svg' height='9'/></i>
                            <i class='ml-2' v-if='activeProjectDefault.isNightMealOrderVisible && slotProps.data.nightMealActualValue > slotProps.data.nightMealExpectedValue'><img src='/layout/images/icon/up.svg' height='9'/></i>
                            <i class='ml-2' v-if='activeProjectDefault.isNightMealOrderVisible && slotProps.data.nightMealActualValue == slotProps.data.nightMealExpectedValue'><img src='/layout/images/icon/minus.svg' height='9'/></i>
                        </template>
                    </Column>

                    <Column field='nightMealCalculatedValue' header='Gece Satış' :sortable='false' style='width:5.5rem;' bodyClass='text-center' v-if='activeProjectDefault.isNightMealVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Gece Satış</span>
                            <span class="validQtyDefault">{{ slotProps.data.nightMealCalculatedValue }}</span>
                        </template>
                    </Column>

                    <Column field='snackExpectedValue' header='Kumanya Sipariş' :sortable='false' style='width:5.5rem;' bodyClass='text-center' v-if='activeProjectDefault.isSnackVisible && activeProjectDefault.isSnackOrderVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Kumanya Sipariş</span>
                            <span class="validQtyDefault">{{ slotProps.data.snackExpectedValue }}</span>
                        </template>
                    </Column>

                    <Column field='snackActualValue' header='Kumanya Servis' :sortable='false' style='width:7rem;' bodyClass='text-center' v-if='activeProjectDefault.isSnackVisible && activeProjectDefault.isSnackOrderVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Kumanya Servis</span>
                            <span :class='actualQtyClass(slotProps.data.snackActualValue)'>{{ slotProps.data.snackActualValue }}</span>
                        
                            <i class='ml-2' v-if='activeProjectDefault.isSnackOrderVisible && slotProps.data.snackActualValue < slotProps.data.snackExpectedValue'><img src='/layout/images/icon/down.svg' height='9'/></i>
                            <i class='ml-2' v-if='activeProjectDefault.isSnackOrderVisible && slotProps.data.snackActualValue > slotProps.data.snackExpectedValue'><img src='/layout/images/icon/up.svg' height='9'/></i>
                            <i class='ml-2' v-if='activeProjectDefault.isSnackOrderVisible && slotProps.data.snackActualValue == slotProps.data.snackExpectedValue'><img src='/layout/images/icon/minus.svg' height='9'/></i>
                        </template>
                    </Column>

                    <Column field='snackCalculatedValue' header='Kumanya Satış' :sortable='false' style='width:5.5rem;' bodyClass='text-center' v-if='activeProjectDefault.isSnackVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Kumanya Satış</span>
                            <span class="validQtyDefault">{{ slotProps.data.snackCalculatedValue }}</span>
                        </template>
                    </Column>

                    <Column field='iftarExpectedValue' header='İftar Sipariş' :sortable='false' style='width:5rem' bodyClass='text-center' v-if='activeProjectDefault.isIftarVisible && activeProjectDefault.isIftarOrderVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>İftar Sipariş</span>
                            <span class="validQtyDefault">{{ slotProps.data.iftarExpectedValue }}</span>
                        </template>
                    </Column>

                    <Column field='iftarActualValue' header='İftar Servis' :sortable='false' style='width:7rem' bodyClass='text-center' v-if='activeProjectDefault.isIftarVisible && activeProjectDefault.isIftarOrderVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>İftar Servis</span>
                            <span :class='actualQtyClass(slotProps.data.iftarActualValue)'>{{ slotProps.data.iftarActualValue }}</span>
                        
                            <i class='ml-2' v-if='activeProjectDefault.isIftarOrderVisible && slotProps.data.iftarActualValue < slotProps.data.iftarExpectedValue'><img src='/layout/images/icon/down.svg' height='9'/></i>
                            <i class='ml-2' v-if='activeProjectDefault.isIftarOrderVisible && slotProps.data.iftarActualValue > slotProps.data.iftarExpectedValue'><img src='/layout/images/icon/up.svg' height='9'/></i>
                            <i class='ml-2' v-if='activeProjectDefault.isIftarOrderVisible && slotProps.data.iftarActualValue == slotProps.data.iftarExpectedValue'><img src='/layout/images/icon/minus.svg' height='9'/></i>
                        </template>
                    </Column>

                    <Column field='iftarCalculatedValue' header='İftar Satış' :sortable='false' style='width:5.5rem;' bodyClass='text-center' v-if='activeProjectDefault.isIftarVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>İftar Satış</span>
                            <span class="validQtyDefault">{{ slotProps.data.iftarCalculatedValue }}</span>
                        </template>
                    </Column>

                    <Column field='sahurExpectedValue' header='Sahur Sipariş' :sortable='false' style='width:5rem' bodyClass='text-center' v-if='activeProjectDefault.isSahurVisible && activeProjectDefault.isSahurOrderVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Sahur Sipariş</span>
                            <span class="validQtyDefault">{{ slotProps.data.sahurExpectedValue }}</span>
                        </template>
                    </Column>

                    <Column field='sahurActualValue' header='Sahur Servis' :sortable='false' style='width:7rem;' bodyClass='text-center' v-if='activeProjectDefault.isSahurVisible && activeProjectDefault.isSahurOrderVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Sahur Servis</span>
                            <span :class='actualQtyClass(slotProps.data.sahurActualValue)'>{{ slotProps.data.sahurActualValue }}</span>
                        
                            <i class='ml-2' v-if='activeProjectDefault.isSahurOrderVisible && slotProps.data.sahurActualValue < slotProps.data.sahurExpectedValue'><img src='/layout/images/icon/down.svg' height='9'/></i>
                            <i class='ml-2' v-if='activeProjectDefault.isSahurOrderVisible && slotProps.data.sahurActualValue > slotProps.data.sahurExpectedValue'><img src='/layout/images/icon/up.svg' height='9'/></i>
                            <i class='ml-2' v-if='activeProjectDefault.isSahurOrderVisible && slotProps.data.sahurActualValue == slotProps.data.sahurExpectedValue'><img src='/layout/images/icon/minus.svg' height='9'/></i>
                        </template>
                    </Column>

                    <Column field='sahurCalculatedValue' header='Sahur Satış' :sortable='false' style='width:5.5rem;' bodyClass='text-center' v-if='activeProjectDefault.isSahurVisible'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Sahur Satış</span>
                            <span class="validQtyDefault">{{ slotProps.data.sahurCalculatedValue }}</span>
                        </template>
                    </Column>

                    <Column field='paymentTotal' header='Toplam' :sortable='false' style='width:10rem;' bodyClass='text-right'>
                        <template #body='slotProps'>
                            <span class="paymentTotal">{{ formatPrice(slotProps.data.paymentTotal) }}</span> <span class='font-light ml-1'>&#8378;</span>
                        </template>
                    </Column>

                    
                    <Column style='width:4rem;' header='Güncelle' bodyClass='text-center'>
                        <template #body='slotProps'>
                            <Button icon='pi pi-pencil' class='p-button-rounded p-button-primary' @click='editFoodPayment(slotProps.data)'/>
                        </template>
                    </Column>

                </DataTable>

                <Toolbar class='mb-2 mt-4'>
                    <template v-slot:start>
                            <h5 class='mb-0'>Toplam Ciro <span class="text-green-600">{{ formatPrice(report.paymentTotal) }} &#8378;</span></h5>
                    </template>
                </Toolbar>

            </div>
        </div>
    </div>

</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import FoodPaymentService from '../../services/foodPaymentService';
import store from '@/store';
import moment from 'moment';
import ProjectDefaultService from '../../services/projectDefaultService';
import ReportTotals from '../../components/shared/ReportTotals';
import FirmService from '../../services/firmService';

export default {
    components: { ReportTotals },
    data() {
        return {activeProjectDefault:{
                breakfastPrice: "",
                lunchPrice: "",
                dinnerPrice: "",
                snackPrice: "",
                iftarPrice: "",
                sahurPrice: "",
                nightMealPrice: "",
                isBreakfastVisible: "",
                isLunchVisible: "",
                isDinnerVisible: "",
                isSnackVisible: "",
                isIftarVisible: "",
                isSahurVisible: "",
                isNightMealVisible: "",
                isBreakfastOrderVisible: "",
                isLunchOrderVisible: "",
                isDinnerOrderVisible: "",
                isIftarOrderVisible: "",
                isSnackOrderVisible: "",
                isSahurOrderVisible: "",
                isNightMealOrderVisible: "",
            },
            firmId : 0,
            firms: [],
            zeroCalculateDialog:false,
            loadingIndicator: false,
            startDate: null,
            endDate:null,
            paymentDateAsTimeStamp: 0,
            projectId: 0,
            userId: 0,
            priceUpdateDialog: false,
            calculateDialog: false,
            deleteActualValuesDialog: false,
            report:{
                breakfastTotal: 0,
                breakfastExpected: 0,
                breakfastActual: 0,
                breakfastCalculated:0,
                lunchTotal: 0,
                lunchExpected: 0,
                lunchActual: 0,
                lunchCalculated:0,
                dinnerActual: 0,
                dinnerTotal: 0,
                dinnerExpected: 0,
                dinnerCalculated:0,
                snackActual: 0,
                snackTotal: 0,
                snackExpected: 0,
                snackCalculated:0,
                sahurActual: 0,
                sahurTotal: 0,
                sahurExpected: 0,
                sahurCalculated:0,
                iftarActual: 0,
                iftarTotal: 0,
                iftarExpected: 0,
                iftarCalculated:0,
                nightMealExpected:0,
                nightMealActual:0,
                nightMealTotal:0,
                nightMealCalculated:0,
                paymentTotal: 0,
                totalExpected:0,
                totalActual:0,
                totalCalculated:0,
                paymentTotalVat:0
            },
            foodPaymentList: null,
            updateActualValueDialog: false,
            deleteProductDialog: false,
            deleteProductsDialog: false,
            submitted: false,
            dataItem: {
                projectId: 0,
                paymentDate: '',
                managerUserId: 0,
            },

            display: false,
            visibleLeft: false,

        };
    },
    foodPaymentService: null,
    projectDefaultService: null,
    firmService: null,
    created() {
        this.firmService = new FirmService();
        this.projectId = store.getters.getActiveProject.id;
        this.userId = store.getters.getUserId;
        this.foodPaymentService = new FoodPaymentService();
        this.projectDefaultService = new ProjectDefaultService();
        this.activeProjectDefault = store.getters.getActiveProject.projectDefaults;
        this.initFilters();
    },

    async mounted() {
        this.startDate = moment().startOf('month').toDate();
        this.endDate = moment().endOf('month').toDate();
    },
    methods: {
        async getFoodPaymentReport() {
            let startDate =moment(this.startDate).valueOf();
            let endDate =moment(this.endDate).valueOf();

            let getReportResponse = await this.foodPaymentService.getProjectFoodReportsForDailyReport(this.projectId,startDate, endDate);
            if (getReportResponse.isSuccess) {
                this.report = getReportResponse.data;
                this.loadingIndicator = false;
            }
        },

        async exportExcel() {
            this.loadingIndicator = true;
            let startDate =moment(this.startDate).valueOf();
            let endDate =moment(this.endDate).valueOf();
            let excelExports = await this.foodPaymentService.getProjectFoodPaymentsForDailyReportExcel(this.projectId, startDate, endDate );
            let blob = new Blob([excelExports.data], { type: excelExports.headers['content-type'] });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "Proje Raporu - " + this.startDate  + ".xlsx";
            link.click();
            this.loadingIndicator = false;
        },


        async refreshFoodPaymentLists() {
            this.loadingIndicator = true;
            let startDate =moment(this.startDate).valueOf();
            let endDate =moment(this.endDate).valueOf();

            let getResponse = await this.foodPaymentService.getProjectFoodPaymentsForDailyReport(this.projectId, startDate, endDate);
            if (getResponse.isSuccess) {
                this.foodPaymentList = getResponse.data;
                this.loadingIndicator = false;
            }
            await this.getFoodPaymentReport();
        },

        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        formatDate(value) {
            moment.locale('tr');
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY dddd');
        },
        editFoodPayment(data) {
            let newDate = moment(data.paymentDate).valueOf();
            this.$router.push({ name: 'calculateFoodPaymentWithPaymentDate', params: { paymentDate: newDate } });
        },

        actualQtyClass(data) {
            return [
                {
                    'zeroQty': data === 0,
                    'validQty': data > 0,
                },
            ];
        },
        formatPrice(value) {
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        }


    },
};
</script>

<style scoped lang='scss'>


.zeroQty {
    color: #FF5252;
    font-weight: bold;
    font-size: 16px;
}

.validQty {
    color: #495057;
    font-weight: bold;
    font-size: 16px;
}

.paymentTotal {
    font-weight: bold;
    font-size: 16px;
}

.validQtyDefault {
    font-weight: bold;
    font-size: 15px;
}

</style>



